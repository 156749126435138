import React from "react";
import Sequence from "../../models/course/sequence";
import stepsMap from "./stepsMap";
import Section from "../../models/course/section";

const sequenceMap = (sequence: Sequence, sequenceKey: string, section: Section) => {
    let sequenceOutput = (
        <div>
            {sequence.label}
            <ol>
                {stepsMap(sequence.steps, sequenceKey, section)}
                {
                    typeof sequence.redirect != "undefined" && sequence.redirect != null ?
                        <li>Then redirect to <a href={"#" + sequence.redirect}>{sequence.redirect}</a></li> : null
                }
            </ol>

            <hr/>
        </div>
    )
    return sequenceOutput
}

export default sequenceMap;

