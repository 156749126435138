import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import VideoPlayer from "./video-player/videoPlayer";
import {IconButton} from "@material-ui/core";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import VideoStep from "../models/course/video-step";
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            position: 'absolute',
            width: '50%',
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: 0,
        },
    }),
);

export default function VideoModal(props) {
    const videoUrl: string = props.videoUrl;
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        console.log("OnEnded called from videoModal", typeof props.nextStep)
        setOpen(false);
    };

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <VideoPlayer
                videoURL={videoUrl}
                title="Preview"
                showControls={true}
                volume={0.8}
                video={props.video}
                background={''}
                enableScrubbing
            >
            </VideoPlayer>
        </div>
    );

    return (
        <span> {props.label}
            <IconButton color="primary" aria-label="play video" onClick={handleOpen}>
                <PlayCircleOutlineIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </span>
    );
}