import React, {FunctionComponent} from "react"
import courseService from "../services/course";
import newCourseService from "../services/course_service";
import AuthenticatedLayout from "../components/layouts/authenticatedLayout"
import {Grid} from "@material-ui/core";
import SectionPreviewComponent from "../components/course-browser/section-preview";
import NavbarInContext from "../components/navBarInContext";
import CourseHOC from "../hoc/courseHoc";
import {Container} from "react-bootstrap";

const MainPage: FunctionComponent = () => {

    const steps = Object.keys(courseService.steps);
    const paths = Object.keys(courseService.paths);
    const sections = Object.keys(newCourseService.sections);
    console.log(newCourseService.sections)

    return (
        <AuthenticatedLayout title="Overview">
            <Container style={{
                "position": "relative"
            }}>
                <h1>Sections</h1>
                <Grid container spacing={1} alignItems={"stretch"} justify={"space-evenly"}>
                    {
                        Object.entries(newCourseService.sections).map(([key, val], index) =>
                            <SectionPreviewComponent index={index} section={val} key={key} />
                        )
                    }
                </Grid>
            </Container>
        </AuthenticatedLayout>
    )
}

export default MainPage
