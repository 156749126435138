import React from "react"
import {Card, CardActions, CardContent, CardHeader, Collapse, createStyles, Grid, Theme} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';

import stepsMap from "./stepsMap";
import sequenceMap from "./sequenceMap";
import Section from "../../models/course/section";
import newCourseService from "../../services/course_service";
import {observer} from "mobx-react";
import FullScreenDialog from "./sectionFullScreenDialog";
import ToggleViewButton from "./toggle-view-button";
import {makeStyles} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";

const useStyles = theme =>
    createStyles({
        root: {
            maxWidth: 345,
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
    });

class SectionPreviewComponent extends React.Component<any, any> {
    state = {
        expanded: false
    }
    sequencesMap = (section: Section) => {
        let sequences = (<div><p>No Sequences</p></div>);
        if(section.hasOwnProperty("sequences")) {
            sequences = (
                <div>
                    <h5>Sequences</h5>
                    {Object.entries(section.sequences).map(([key, val],  index)=>{
                        return (
                            <div key={key}>
                                <a id={key} />
                                <h6>{key}</h6>
                                <p style={{fontSize:"10px"}}>
                                    Marker: {val.marker} <br/>
                                    Action: {val.action} <br/>
                                    lessonEnd: {val.lessonEnd ? "true":"false"} <br/>
                                    {val.countdownTime ? "countdownTime: " + val.countdownTime : null}

                                </p>
                                {sequenceMap(val, key, section)}
                            </div>
                        )
                    })}
                </div>
            )
            return sequences;
        }

    }

    render() {
        const { classes } = this.props;
        if(this.props.section == null) return <Grid item xs={6} key={this.props.index}>EMPTY</Grid>
        return (
            <Grid item xs={12} sm={12} md={6} lg={4} key={this.props.index}>
                <Card>
                    <CardHeader
                        action={
                            <CardActions disableSpacing>

                                <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: this.state.expanded,
                                })}
                                onClick={()=>{this.setState((state)=>({expanded: !state.expanded}))}}
                                aria-expanded={this.state.expanded}
                                aria-label="show more"
                                >
                                <ExpandMoreIcon />
                                </IconButton>
                                <FullScreenDialog
                                    section={this.props.section}
                                />
                            </CardActions>
                        }
                        title={this.props.section.key}
                    />
                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>

                    <CardContent>
                        {this.sequencesMap(this.props.section)}
                    </CardContent>
                    </Collapse>

                </Card>
            </Grid>
        )
    }
}

export default withStyles(useStyles)(SectionPreviewComponent);

