import React from "react";
import CourseContext from "../context/courseContext";
import Step from "./course-browser/step";

const CourseTimer = (props) => {
    return (
            <CourseContext.Consumer>{

                (context) => {
                    return (
                        <div className="timer">
                            {context.seconds}s
                            <button className={`button button-primary button-primary-${context.timerIsActive ? 'active' : 'inactive'}`} onClick={context.toggleTimer}>
                                {context.timerIsActive ? 'Pause' : 'Start'}
                            </button>
                            <button className="button" onClick={context.resetTimer}>
                                Reset
                            </button>
                        </div>
                    )
                }
            }</CourseContext.Consumer>

    )
}
export default CourseTimer