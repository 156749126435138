import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MovieIcon from '@material-ui/icons/Movie';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Step from './step';
import Sequence from "../../models/course/sequence";
import ChoiceStep from "../../models/course/choice-step";
import {string} from "prop-types";
import newCourseService from "../../services/course_service";
import CourseContext from "../../context/courseContext";
import CourseTimer from "../courseTimer";
import CourseHOC from "../../hoc/courseHoc";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//using React Hooks in functional component as an exercise. React seems to be moving toward functional components
const FullScreenDialog = (props) => {
    const classes = useStyles();
    const [data, setData] = React.useState({
        open:false,
    });

    return (
        <CourseHOC
            section={props.section}
            sectionKey={props.sectionKey}
            sequenceKey={props.sequenceKey}
            stepIndex={props.stepIndex}
            adminOverview>
            <CourseContext.Consumer>
                { (context) => {

                    const handleClickOpen = () => {
                        console.log(context.courseState);
                        let newState = {...context.courseState};
                        newState.currentSection = props.section;
                        context.setCourseState(newState);

                        let newData = {...data};
                        newData.open = true;
                        setData(newData);
                    };

                    const handleClose = () => {
                        let newData = {...data};
                        newData.open = false;
                        // newData.showOverlayChoices = false;
                        // newcontext.section = props.section;
                        // newData.currentSequence = props.section.sequences['opening'];
                        // newcontext.stepIndex = 0;
                        // newcontext.step = props.section.sequences['opening'].steps[0];
                        // newData.totalSteps = props.section.sequences['opening'].steps.length;
                        // newData.previousSequenceKey = "";
                        setData(newData);
                        context.resetTimer();
                    };
                    return (
                        <>
                            <IconButton variant="outlined" color="primary" size="medium" onClick={handleClickOpen}>
                                <MovieIcon />
                            </IconButton>
                            <Dialog maxWidth={false} open={data.open} onClose={handleClose} TransitionComponent={Transition}>
                                <AppBar className={classes.appBar}>
                                    <Toolbar>
                                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                                        <Typography variant="h6" className={classes.title}>
                                            {context.sequence.label}
                                        </Typography>

                                        <Button  color="inherit" onClick={context.playFromTheBeginning}>
                                            Start Over
                                        </Button>

                                    </Toolbar>
                                </AppBar>
                                <Step />
                                <AppBar className={classes.appBar}>
                                    <Toolbar>
                                        <Typography variant="h6" className={classes.title}>

                                        </Typography>
                                        <CourseTimer />
                                        <Button color="inherit" onClick={context.loadPrevStep}>
                                            Prev Step
                                        </Button>
                                        <div>
                                            {context.stepIndex + 1 + " / " + context.totalSteps }
                                        </div>
                                        <Button autoFocus color="inherit" onClick={() => context.loadNextStep({action:"nextBtn"})}>
                                            Next Step
                                        </Button>
                                    </Toolbar>
                                </AppBar>
                            </Dialog>
                        </>
                    )
                }}

            </CourseContext.Consumer>
        </CourseHOC>
    );
}
FullScreenDialog.defaultProps = {
    section: newCourseService.sections.open,
    sequenceKey: 'opening',
    stepIndex: 0,
}
export default FullScreenDialog