import ChoiceStep from "../../models/course/choice-step";
import React from "react";

const choicesMap = (choiceStep: ChoiceStep) : any => {
    //console.log(choiceStep);
    return choiceStep.options.map((value, index) => {
        return (
            typeof value.destinationKey != "undefined" ?
            <li key={index}><a href={"#"+value.destinationKey}>{value.label}</a></li>
                : <li key={index}>{value.label}</li>

        )
    })
}

export default choicesMap