import VideoStep from "../../models/course/video-step";
import VideoModal from "../videoModal";
import ChoiceStep from "../../models/course/choice-step";
import React from "react";
import choicesMap from "./choicesMap";
import FullScreenDialog from "./sectionFullScreenDialog";
import Section from "../../models/course/section";
import Sequence from "../../models/course/sequence";
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption';
const stepsMap = (steps, sequenceKey: string, section: Section) => {

    return steps.map((value, index) => {
        if(value instanceof VideoStep ) {
            if(typeof value.video == "undefined") {
                return (
                    <li key={index}>MISSING VIDEO</li>
                )
            } else {
                return (<li key={index}>
                    <VideoModal video={value.video} videoUrl={value.video?.src} label={value.key} nextStep={steps[index+1]} />
                    <FullScreenDialog
                        section={section}
                        sectionKey={section.key}
                        sequenceKey={sequenceKey}
                        stepIndex={index}
                    />
                    <a href={'https://amara.org/en/teams/seventeen-days/videos/?q=' + value.key} target={'_blank'}><ClosedCaptionIcon/></a>
                    <small>{value.video?.src}</small>
                </li>)
            }

        } else if(value instanceof ChoiceStep) {
            let backgroundImg = (step: ChoiceStep) => {

                if (step.backgroundImage) {
                    return(
                        <img key={step.backgroundImage} style={{maxWidth:"100%"}} src={require("../../images/menus/" + step.backgroundImage)} />
                    )
                } else {
                    return null
                }

            }
            return (
                    <li key={index}>{value.key}
                        <FullScreenDialog
                            section={section}
                            sectionKey={section.key}
                            sequenceKey={sequenceKey}
                            stepIndex={index}
                        />
                        {backgroundImg(value)}
                        <ol type={'A'}>{choicesMap(value)}</ol>
                    </li>
            )
        }
    })
}

export default stepsMap;